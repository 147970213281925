import React, { useState } from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import { Slide } from 'react-reveal';
import Wave from 'react-wavify';
import { InPageBot } from "@leadoo/react-bot";
import AnchorLink from 'react-anchor-link-smooth-scroll';

// images
import banner_img from "../images/kundfaktura_banner.svg";
import safe_icon from "../images/skicka_faktura_safe.png";
import miljo_icon from "../images/skicka_faktura_miljo.png";
import spara_icon from "../images/skicka_faktura_spara.png";

import e_faktura_img from "../images/e_faktura.png";
import print_tjanst_img from "../images/skicka_faktura_print_tjanst.png";

const BOT_CODE = "kxFV5wCG";

function Kundfakturor() {
    const [chatbot_1, setChatbot_1] = useState(false);

    return(
        <Layout>
            <Helmet>
                <title>Customer invoices | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Send customer invoices easily and efficiently regardless of format." />
                <meta name="keywords" content="Outgoing invoices | Customer invoices" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Send customer invoices easily and efficiently regardless of format." />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="en_EN" />
                <meta property="og:url" content="https://www.crediflow.com/customer-invoices" />
                <link rel="cannonical" href="https://www.crediflow.com/customer-invoices" />
            </Helmet>

            <section>
                <div className="w-screen bg-white border-t border-gray-100">
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="grid lg:grid-cols-2 py-24 gap-20">
                            <Slide left>
                                <div className="flex flex-col items-start justify-center">
                                    <h1 className="title-font font-semibold text-3xl text-blue-custome">Customer invoices</h1>
                                    <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                        Whoever you want to send to and regardless of the 
                                        format, we offer a simple and efficient 
                                        way to manage your outgoing invoices.
                                        <br/><br/>
                                        Our service takes care of all your outgoing 
                                        invoices and ensures that they reach the 
                                        recipient in the way they want and can 
                                        receive them, e-invoice, PDF via email or 
                                        distribution via post. From day one, we 
                                        digitize and streamline your handling while 
                                        proactively working towards more efficient 
                                        invoice formats and further efficiency.
                                        <br/><br/>
                                        Maybe a customer would rather receive an EDI 
                                        invoice but you can't? Your customer probably 
                                        means e-invoice but says EDI. Let us help you.
                                    </p>
                                    <button onClick={() => setChatbot_1(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Get in contact</button>
                                    {chatbot_1 
                                        ? 
                                            <div className="h-full w-full mt-10">
                                                <InPageBot code={BOT_CODE} seamless/>
                                            </div> 
                                        : "" 
                                    }
                                </div>
                            </Slide>
                            <Slide right>
                                <div className="w-2/3 lg:w-full p-4 mx-auto">
                                    <img className="w-full" src={banner_img} alt="manage outgoing invoices" />
                                </div>
                            </Slide>
                        </div>
                    </div>
                    <Wave 
                        className="-mb-2"
                        fill='#F3F4F6'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 50,
                        speed: 0.15,
                        points: 3
                        }}
                    />
                </div>
            </section>




            <section className="bg-gray-100">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="pb-10 flex text-md">
                        <Link to="/" className="flex items-center text-blue-800 hover:text-blue-600">Home</Link>
                        <span className="text-gray-400 px-3">/</span>
                        <span className="text-gray-400">customer-invoices</span>
                    </div>
                    <div className="grid md:grid-cols-3 gap-8">
                        <AnchorLink href="#sendInvoice">
                            <div className="bg-white rounded-md">
                                <div className="w-full px-6 py-8 ">
                                    <div className="w-40 mx-auto">
                                        <img className="" src={safe_icon} alt="outgoing invoices"/>
                                    </div>
                                    <p className="mt-4 text-center text-gray-500">
                                        The invoice reaches the recipient directly 
                                        and without the risk of it being lost on the way.
                                    </p>
                                </div>
                            </div>
                        </AnchorLink>

                        <AnchorLink href="#sendInvoice">
                            <div className="bg-white rounded-md">
                                <div className="w-full px-6 py-8 ">
                                    <div className="w-40 mx-auto">
                                        <img className="" src={spara_icon} alt="receive e-invoices"/>
                                    </div>
                                    <p className="mt-4 text-center text-gray-500">
                                        The invoice is easier for the recipient to 
                                        handle, which means you get paid correctly and on time.
                                    </p>
                                </div>
                            </div>
                        </AnchorLink>

                        <AnchorLink href="#sendInvoice">
                            <div className="bg-white rounded-md">
                                <div className="w-full px-6 py-8 ">
                                    <div className="w-40 mx-auto">
                                        <img src={miljo_icon} alt="receive e-invoices"/>
                                    </div>
                                    <p className="mt-4 text-center text-gray-500">
                                        If the environmental perspective is important 
                                        to your company, e-invoicing is the obvious choice.
                                    </p>
                                </div>
                            </div>
                        </AnchorLink>
                    </div>
                </div>
            </section>




            <section className="bg-gray-100 pt-36 pb-28" id="sendInvoice">
                <div className="container mx-auto px-4 xl:px-24" >
                    <hr className="text-gray-200"/>
                </div>
            </section>




            <section className="bg-gray-100" >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="flex flex-col items-start justify-center">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">E-invoice &#8211; Best for you and your customers</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                Simplify for both yourself and your customers 
                                by sending e-invoices. In addition to the 
                                fact that since April 2019 in Sweden there is a law on 
                                sending e-invoices to the public sector, we 
                                also see today that e-invoicing is increasingly a 
                                requirement in procurements. Although e-invoicing 
                                has a transaction cost, it is cost-saving for 
                                the entire organization as it streamlines administration.
                            </p>
                            <Link to="/demo" className="mt-10 px-7 py-2 rounded-full text-white border border-blue-custome bg-blue-custome shadow-md hover:opacity-90">Book Demo</Link>
                        </div>
                
                        <div className="w-2/3 lg:w-full p-4 mx-auto">
                            <img className="w-full" src={e_faktura_img} alt="Send e invoice" />
                        </div>
                    </div>
                </div>
            </section>


            <section className="bg-gray-100 py-28">
                <div className="container mx-auto px-4 xl:px-24" >
                    <hr className="text-gray-200"/>
                </div>
            </section>


            <section className="bg-gray-100 pb-32 " >
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="grid lg:grid-cols-2 gap-20">
                        <div className="w-2/3 lg:w-full p-4 mx-auto">
                            <img className="w-full" src={print_tjanst_img} alt="Skicka e fakura" />
                        </div>
                        <div className="flex flex-col items-start justify-center">
                            <h1 className="title-font font-semibold text-3xl text-gray-900">Print service  &#8211; An unnecessary job we do for you at a lower cost</h1>
                            <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                Although digital formats are increasing, companies 
                                need to send both invoices and other information 
                                by post. By using our service, you don't have to 
                                spend time on printing, envelopes, postal delivery, 
                                etc. and it's also cheaper than doing it yourself.
                                <br/><br/>
                                With our print service, you reach your recipient's 
                                physical mailboxes with a couple of clicks. Regardless 
                                of what you want to send: Invoices, information 
                                letters, salary specifications, advertising.
                            </p>
                            <Link to="/demo/" className="px-7 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Book Demo</Link>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    );
}
export default Kundfakturor;